exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-referral-form-js": () => import("./../../../src/pages/referralForm.js" /* webpackChunkName: "component---src-pages-referral-form-js" */),
  "component---src-pages-referral-js": () => import("./../../../src/pages/referral.js" /* webpackChunkName: "component---src-pages-referral-js" */),
  "component---src-pages-referral-refer-english-content-writer-js": () => import("./../../../src/pages/referral/refer_english_content_writer.js" /* webpackChunkName: "component---src-pages-referral-refer-english-content-writer-js" */),
  "component---src-pages-referral-refer-full-stack-developer-js": () => import("./../../../src/pages/referral/refer_full_stack_developer.js" /* webpackChunkName: "component---src-pages-referral-refer-full-stack-developer-js" */),
  "component---src-pages-referral-refer-senior-devops-engineer-js": () => import("./../../../src/pages/referral/refer_senior_devops_engineer.js" /* webpackChunkName: "component---src-pages-referral-refer-senior-devops-engineer-js" */),
  "component---src-pages-referral-refer-senior-front-end-developer-js": () => import("./../../../src/pages/referral/refer_senior_front_end_developer.js" /* webpackChunkName: "component---src-pages-referral-refer-senior-front-end-developer-js" */),
  "component---src-pages-referral-refer-senior-seo-specialist-js": () => import("./../../../src/pages/referral/refer_senior_seo_specialist.js" /* webpackChunkName: "component---src-pages-referral-refer-senior-seo-specialist-js" */),
  "component---src-pages-referral-result-js": () => import("./../../../src/pages/referralResult.js" /* webpackChunkName: "component---src-pages-referral-result-js" */),
  "component---src-pages-request-fail-js": () => import("./../../../src/pages/requestFail.js" /* webpackChunkName: "component---src-pages-request-fail-js" */),
  "component---src-pages-request-js": () => import("./../../../src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */),
  "component---src-pages-request-result-js": () => import("./../../../src/pages/requestResult.js" /* webpackChunkName: "component---src-pages-request-result-js" */),
  "component---src-pages-terms-of-service-js": () => import("./../../../src/pages/terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-service-js" */)
}

